import { Component, Inject } from '@angular/core';

import { Platform, AlertController, MenuController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { CommonService } from './services/common.service';
import { Router } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Market } from '@ionic-native/market/ngx';
import { app, languageInitializer, serverUrl, storageVariable } from 'src/environments/environment';
import { AjaxService } from './services/ajax.service';
import { Network } from '@ionic-native/network/ngx';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { WebsocketService } from './services/websocket.service';
import { TranslateConfigService } from './services/translate-config.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent {
  isModalOpen = false;
  loadingRefresh = false;
  // restrictedCommandImei = ["357544376950214", "864431049839964"];
  appIcon = {
    "Dashboard": "home",
    "Grid view": 'md-browsers',
    "Map view": 'map',
    "Track history": "pin",
    // "Geo fence": 'navigate',

    "Alerts": "warning",
    "Settings": "settings",

    // "Camera": "camera",
    "Manage": "folder",
    // "Profile": 'person',
    // "Users": 'people',
    "Maintanence": 'construct',
    // "Expense":'md-browsers',
    // "Operators": 'nuclear',
    // "Diagnosis": "globe",
    // "User diagnosis": "planet",
    // "Tickets": 'filing',
    "Reports": "stats",
    "Trip": "settings",
    "Student details": "md-man",
    "Class details": "ios-people",
    "Parent-details": "ios-contacts",
    "Tag details": "ios-ribbon",
    "Route n Trip": "ios-bus",
    "Gate details": 'ios-card',
    "Student attendance": "ios-clipboard",
    "School E&D": "business",
    "Excel validation": 'warning',
    "Broadcast sms": "ios-mail",
    "Change number": "cloud-upload",

    "Log out": "log-out",

    "Home": "home",
    "Company": 'person',
    "Vehicle creation": 'stats',
    "Dealer creation": "add-circle-outline",
    "Device activation": "md-browsers",
    "Command": 'mail-open',
    "Stock uploader": "cloud-upload",
    "Stocks": "logo-buffer",
    "Company creation": "stats",
    "Subscription": "bookmarks",
    "Stocks report": "logo-euro",
    "checkImei": "ios-mail",
    "Log-out": "log-out",

    "About": "md-browsers",
    "Log-Out": "log-out",


    "poc-geolocation": "pin"
  }

  appUrl = {
    "Dashboard": '/tabs/members/dashboard',
    "Grid view": '/tabs/gridview/All',
    "Map view": '/tabs/mapview/All',
    "Track history": "/trackhistory",
    // "PDF": '/home',
    // "Geo fence": '/geofence/All/null',

    "Alerts": "/tabs/alerts/All",

    "Settings": "settings/All",
    // "Camera": "/camera",
    "Manage": "/generalform",
    // "Profile": 'manage-fleet/Profile',
    // "Operators": 'manage-fleet/Operator',
    // "Users": 'manage-fleet/Users',
    // "Tickets": 'ticket',
    "Maintanence": 'maintanence',
    // "Expense":'expense-maintenance',
    // "Diagnosis": "/diagnosis",
    // "User diagnosis": "diagnosis-user",
    "Reports": "/reports/null",
    "Trip": "trip-summary",
    "Log out": "tabs-login/members/login",
    "Home": "tabs-login/new-dashboard",
    "Company": 'tabs-login/dashboard',
    "Vehicle creation": 'tabs-login/vehicle-creation',
    "Dealer creation": 'tabs-login/add-delar',
    "Device activation": 'tabs-login/device-activation',
    "Command": "tabs-login/device-commands",
    "Stock uploader": "tabs-login/stock-uploader",
    "Stocks": "tabs-login/stocks",
    "Company creation": "tabs-login/dashboard/add-company",
    "Subscription": "tabs-login/subscription",
    "checkImei": "tabs-login/check-imei",
    "Sales report": "tabs-login/sales-report",

    "Change number": "/change-number",

    "Student details": "/student-details",
    "Class details": "/class-table",
    "Parent-details": "/parent-table",
    "Tag details": "/tag-table",
    "Route n Trip": "/route-trip",
    "Gate details": '/gate-table',
    "Student attendance": "/student-attendence",
    "School E&D": "/school-enable",
    "Excel validation": '/excel-validation',
    "Broadcast sms": "/broadcast-sms",
    "Log-out": "tabs-login/members/login",

    "About": "/about",

    // "Log-Out": "tabs-login/members/login",
    "Log-Out": "tabs-login/members/login",
    "poc-geolocation": "/poc-geolocation",
  }
  public appPages = [
    {
      title: 'Dashboard',
      url: '/tabs/members/dashboard',
      icon: 'home'
    },
    {
      title: 'Grid view',
      url: '/tabs/gridview/All',
      icon: 'md-browsers'
    },
    {
      title: 'Map view',
      url: '/tabs/mapview/All',
      icon: 'map'
    },
    {
      title: 'Geo fence',
      url: '/geofence/All/null',
      icon: 'navigate'
    },
    {
      title: 'Reports',
      url: '/reports/null',
      icon: 'stats'
    },
    {
      title: 'Alerts',
      url: '/tabs/alerts/All',
      icon: 'warning'
    },
    {
      title: 'Settings',
      url: 'settings/All',
      icon: 'settings'
    },
    {
      title: 'Manage',
      url: '/generalform',
      icon: 'globe'
    },
    {
      title: 'Diagnosis',
      url: '/diagnosis',
      icon: 'globe'
    },
    {
      title: 'Operator',
      url: 'manage-fleet/Operator',
      icon: 'warning'
    },
    {
      title: 'Profile',
      url: 'manage-fleet/Profile',
      icon: 'person'
    },
    {
      title: 'Ticket',
      url: 'ticket',
      icon: 'md-browsers'
    },
    {
      title: 'maintanence',
      url: 'maintanence',
      icon: 'md-browsers'
    },
    {
      title: 'Users',
      url: 'manage-fleet/Users',
      icon: 'people'
    },
    {
      title: 'Log out',
      url: '/login',
      icon: 'log-out'
    }
  ];
  myPlatform;
  selectedMenu = "Log out";
  isDealer: boolean = false;
  directory: string = "ltl";
  Language: string;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private commonService: CommonService,
    private router: Router,
    private alertController: AlertController,
    private authenticationService: AuthenticationService,
    private menuController: MenuController,
    private ajaxService: AjaxService,
    private title: Title,
    private websocketService: WebsocketService,
    @Inject(DOCUMENT) private document: HTMLDocument,
    private appVersion: AppVersion,
    private market: Market,
    public translate: TranslateConfigService,
    public trans: TranslateService
  ) {
    this.translate.setInitialAppLanguage();
    this.updateLang();
    this.initializeApp();
  }


  menuObject = [];
  app = {
    logo: 'logo.jpg',
    company: 'company'
  };

  appSideMenu = "listMenu";
  menuOver = false;



  updateLang() {
    this.commonService.updateLanguageDir
      .subscribe(res => {
        this.directory = res == "" ? this.directory : res
        this.trans.use(res)
      })

    var lng = languageInitializer.selectedLang;
    if (lng == "Arabic" || lng == "ar") {
      this.directory = "rtl"
      languageInitializer.directory = "rtl"
      this.Language = "ar";
    } else {
      this.Language = "en";
      languageInitializer.directory = "ltr"
      this.directory = "ltr"
    }
  }

  selectLanguage(event) {
    this.translate.setLanguage(event)
    languageInitializer.directory = event == "en" ? "ltr" : "rtl"
    languageInitializer.selectedLang = event
    if (!(this.trans.store.langs).includes("en"))
      this.trans.store.langs.push("en")
    this.trans.use(event)
    this.commonService.updateLanguageDir.next(languageInitializer.directory)
  }

  closeWebsocket() {
    let vin = JSON.parse(localStorage.selectedVin).vin;
    let duration = JSON.parse(localStorage.appSettings).liveTrackingDisconnect
    const credentials = {
      dashboardVin: JSON.parse(localStorage.selectedVin).vin,
      defaultInterval: duration,
      make: storageVariable.upDatedJsonData.liveDatas[vin].make,
      model: storageVariable.upDatedJsonData.liveDatas[vin].model,
      delay: duration,
      ImeiNo: storageVariable.upDatedJsonData.liveDatas[vin].imeiNo,
      entryPoint: app.entryPoint,
      Check: false,
      emailId: localStorage.userName,
      branchID: localStorage.corpId,
      companyID: localStorage.corpId,
      mode: 'liveTrackDisConnect'
    };
    // if (!this.restrictedCommandImei.includes(storageVariable.upDatedJsonData.liveDatas[vin].imeiNo.imeiNo))
    this.websocketService.reSendRequest(credentials);
    if (this.websocketService.isAlive('livetrack')) {
      this.websocketService.disConnectSocket('livetrack');
    }
  }

  async appUpdate() {
    if (/(android|iPhone|iPad|iPod)/i.test(navigator.userAgent)) {
      if (localStorage.appSettings) {
        console.log(this.appVersion.getAppName())
        let currentVersion = app.appVersion;
        let appSettingsVersion = JSON.parse(localStorage.appSettings)['appComVersion'][app.appName];
        if (appSettingsVersion > currentVersion) {
          const alert = await this.alertController.create({
            header: 'Update Alert',
            backdropDismiss: false,
            message: "A new update is now available. Please update from the appstore or playstore.",
            buttons: [{
              text: 'Cancel',
              role: 'cancel',
              handler: data => {
              }
            },
            {
              text: 'update',
              handler: data => {
                console.log("update that")
                this.authenticationService.logout();
                this.router.navigateByUrl('login')
                this.market.open(app.package);
              }
            }]
          });

          await alert.present();

        }
      }
    }

  }

  tabChanged() {
    this.selectedMenu = "Dashboard"
  }
  initializeApp() {

    this.platform.ready().then(() => {

      window.addEventListener('offline', () => {
        //Do task when no internet connection
        this.commonService.networkChecker()
      });
      window.addEventListener('online', () => {
        this.commonService.alertController.dismiss();
      });

      this.platform.pause.subscribe(e => {
        this.closeWebsocket();
      });

      window.addEventListener('beforeunload', () => {
        this.closeWebsocket();
      });



      this.commonService.companyInfo
        .subscribe(
          res => {
            // this.appUpdate();
            console.table(res);
            this.app["logo"] = res["logo"];
            this.app["company"] = res["companyId"];
            if (window.location.origin == "https://track.remon.in") {
              app.entryPoint = res == "" ? "TTS" : res['entryPoint'];
              app.appName = "Remoncloud";
              serverUrl.Admin = "https://track.remon.in/Admin";
              serverUrl.web = "https://track.remon.in/fleettracking";
              serverUrl.adminSocket = "wss://track.remon.in";
              serverUrl.websocket = "wss://track.remon.in";
              app.loginImgUrl = 'assets/loginLogo/remoncloud.png';
              this.title.setTitle("REMON TRACKING");
              this.document.getElementById('appFavicon').setAttribute('href', 'assets/icon/remon.png');
              app.bgImage = "https://kingstrackimages.s3.amazonaws.com/loginimages/track_remon_in_background.jpg"
            } else if (window.location.origin == "http://lnt-track.sanjhusecuresystems.in/") {
              app.appName = "LNT";
              serverUrl.Admin = "http://lnt-track.sanjhusecuresystems.in/Admin";
              serverUrl.web = "http://lnt-track.sanjhusecuresystems.in//fleettracking";
              serverUrl.adminSocket = "ws://http://lnt-track.sanjhusecuresystems.in";
              serverUrl.websocket = "ws://lnt-track.sanjhusecuresystems.in";

              app.loginImgUrl = 'assets/loginLogo/remoncloud.png';
              this.title.setTitle("LNT TRACKING");
              this.document.getElementById('appFavicon').setAttribute('href', '');
              app.bgImage = "https://kingstrackimages.s3.amazonaws.com/loginimages/track_remon_in_background.jpg"
            } else if (window.location.origin == "http://localhost:8090") {
              app.entryPoint = app.entryPoint == "unknown" ? "unknown" : app.entryPoint;
              this.title.setTitle("MVT Tracking");
              this.document.getElementById('appFavicon').setAttribute('href', 'assets/icon/mvt.png');
              serverUrl.Admin = "http://localhost:8090/Admin";
              serverUrl.web = "http://localhost:8090/fleettracking";
              serverUrl.adminSocket = "ws://localhost:8090";
              serverUrl.websocket = "ws://localhost:8090";
            } else if (window.location.origin == "http://122.165.187.106:8090") {
              app.entryPoint = app.entryPoint == "unknown" ? "unknown" : app.entryPoint;
              this.title.setTitle("MVT Tracking");
              this.document.getElementById('appFavicon').setAttribute('href', 'assets/icon/mvt.png');
              serverUrl.Admin = "http://122.165.187.106:8090/Admin";
              serverUrl.web = "http://122.165.187.106:8090/fleettracking";
              serverUrl.adminSocket = "ws://122.165.187.106";
              serverUrl.websocket = "ws://122.165.187.106";
            } else if (window.location.origin == "http://192.168.1.13:8082") {
              app.entryPoint = app.entryPoint == "unknown" ? "unknown" : app.entryPoint;
              this.title.setTitle("MVT Tracking");
              this.document.getElementById('appFavicon').setAttribute('href', 'assets/icon/mvt.png');
              serverUrl.Admin = "http://192.168.1.13:8082/Admin";
              serverUrl.web = "http://192.168.1.13:8082/fleettracking";
              serverUrl.adminSocket = "ws://192.168.1.13";
              serverUrl.websocket = "ws://192.168.1.13";
            } else if (window.location.origin == "http://192.168.1.10:8081") {
              app.entryPoint = app.entryPoint == "unknown" ? "unknown" : app.entryPoint;
              this.title.setTitle("MVT Tracking");
              this.document.getElementById('appFavicon').setAttribute('href', 'assets/icon/mvt.png');
              serverUrl.Admin = "http://192.168.1.10:8081/Admin";
              serverUrl.web = "http://192.168.1.13:8080/fleettracking";
              serverUrl.adminSocket = "ws://192.168.1.13:8081";
              serverUrl.websocket = "ws://192.168.1.13:8081";
            } else if (window.location.origin == "https://mvtqa.thegoldenelement.com" || (window.location.origin.includes("localhost") && app.appName != "peax")) {
              // || (window.location.origin.includes("localhost") && app.appName != "peax")
              app.entryPoint = app.entryPoint == "unknown" ? "unknown" : app.entryPoint;
              this.title.setTitle("Asset Tracking");
              this.document.getElementById('appFavicon').setAttribute('href', 'assets/icon/ge.png');
              serverUrl.Admin = "https://mvtqa.thegoldenelement.com/Admin";
              serverUrl.web = "https://mvtqa.thegoldenelement.com/fleettracking";
              serverUrl.adminSocket = "wss://mvtqa.thegoldenelement.com";
              serverUrl.websocket = "wss://mvtqa.thegoldenelement.com";
              app.appName = "GE"
              app.loginImgUrl = 'assets/loginLogo/ge.png';
              app.bgImage = 'https://kingstrackimages.s3.amazonaws.com/loginimages/track_thegoldenelement_com_background.jpg';
            } else {
              // if (this.platform.platforms()[0] == "desktop" || this.platform.platforms()[0] == "tablet" || !(/(android|iPhone|iPad|iPod)/i.test(navigator.userAgent))) {
                // app.entryPoint = "VTS";
                this.title.setTitle("Asset Tracking");
                this.document.getElementById('appFavicon').setAttribute('href', 'assets/icon/mvt.png');
              // }
            }

            // if (this.platform.platforms()[0] == "desktop" || this.platform.platforms()[0] == "tablet" || !(/(android|iPhone|iPad|iPod)/i.test(navigator.userAgent))) {
              setTimeout(() => {
                if (localStorage.userName) {
                  this.menuObject = []
                  if (localStorage.loginData) {
                    this.isDealer = false
                    let mainMenu = JSON.parse(JSON.parse(localStorage.getItem('loginData'))[1].mainmenu);
                    // this.menuObject = ["Dashboard", "Grid view", "Map view", "Geo fence", "Reports", "Alerts", "Settings", "Log out"];
                    for (var i = 0; i < Object.keys(this.appIcon).length; i++) {
                      if (mainMenu.indexOf(Object.keys(this.appIcon)[i]) != -1)
                        this.menuObject.push(Object.keys(this.appIcon)[i])
                    }
                  } else if (localStorage.mainMenu) {
                    this.isDealer = true
                    let mainMenu = JSON.parse(localStorage.getItem('mainMenu'));
                    // this.menuObject = ["Dashboard", "Grid view", "Map view", "Geo fence", "Reports", "Alerts", "Settings", "Log out"];
                    for (var i = 0; i < Object.keys(this.appIcon).length; i++) {
                      if (mainMenu.indexOf(Object.keys(this.appIcon)[i]) != -1)
                        this.menuObject.push(Object.keys(this.appIcon)[i])
                    }
                  }
                  // this.menuObject.push("Maintanence");
                  const url = serverUrl.web + "/user/branch/InitialPageuser";
                  const data = { 'userId': localStorage.userName, 'companyId': localStorage.corpId }
                  this.ajaxService.ajaxPostWithString(url, data)
                    .subscribe(res => {
                      localStorage.inItPage = res;
                      if (res == 'dashboard' || res == undefined || res == null) {
                        this.selectedMenu = 'Dashboard';
                      } else if (res == 'gridView') {
                        this.selectedMenu = 'Grid view';
                      } else {
                        this.selectedMenu = res;
                      }
                      this.commonService.dismissLoader();
                    });

                }
              }, 2000);
            // }
          }
        );
      if (localStorage.companyLogo) {
        this.app["logo"] = localStorage.companyLogo;
      }
      if (localStorage.corpId) {
        this.app["company"] = localStorage.corpId;
      }
      this.statusBar.styleLightContent();
      this.splashScreen.hide();

      this.authenticationService.authenticationState.subscribe(state => {

        if (state == true && sessionStorage.login == 'true') {
          if ((localStorage.inItPage == 'dashboard' || localStorage.inItPage == undefined) && app.appName != 'Armoron') {
            this.router.navigateByUrl('tabs/members/dashboard');
            this.selectedMenu = 'Dashboard';
          } else if (localStorage.inItPage == 'gridView' || app.appName == 'Armoron') {
            this.router.navigateByUrl('tabs/gridview/All');
            this.selectedMenu = 'Grid view';
          }

        } else if (state == false && (sessionStorage.login == 'false' || sessionStorage.login == undefined || sessionStorage.login == 'undefined') && (localStorage.login == 'false' || localStorage.login == undefined || localStorage.login == 'undefined')) {
          this.router.navigateByUrl('tabs-login/members/login', { replaceUrl: true });
          if ((localStorage.inItPage == 'dashboard' || localStorage.inItPage == undefined) && app.appName != 'Armoron') {
            this.selectedMenu = 'Dashboard';
          } else if (localStorage.inItPage == 'gridView' || app.appName == 'Armoron') {
            this.selectedMenu = 'Grid view';
          }
        } else {
          if ((localStorage.inItPage == 'dashboard' || localStorage.inItPage == undefined) && app.appName != 'Armoron') {
            this.selectedMenu = 'Dashboard';
            this.router.navigateByUrl('tabs/members/dashboard');
          } else if (localStorage.inItPage == 'gridView' || app.appName == 'Armoron') {
            this.selectedMenu = 'Grid view';
            this.router.navigateByUrl('tabs/gridview/All');
          }
          setTimeout(() => {
            this.loadingRefresh = true;
          }, 10000)

        }
      });
    });

    this.myPlatform = "desktop"
    console.log('platform' + this.myPlatform)
  }

  async logOutFunction(router) {
    localStorage.removeItem('modalFilterData')
    localStorage.setItem("pageSelector", router);
    let logout = this.trans.instant("Are you sure you want to logout from user login")
    if (router == "Log out" || router == "Log-out") {
      const alert = await this.alertController.create({
        header: this.trans.instant('Log out'),
        backdropDismiss: false,
        message: logout.replace("user", localStorage.getItem('corpId')),
        buttons: [{
          text: 'Cancel',
          role: 'cancel',
          handler: data => {
          }
        },
        {
          text: 'Ok',
          handler: data => {
            storageVariable.upDatedJsonData = undefined;
            storageVariable.dashboardData = undefined

            if (localStorage.getItem('pushStatus') == "persisted") {
              let pushDetails = {
                "imeiNo": localStorage.getItem("imeiNo"),
                "appName": app.appName,
                "deviceToken": localStorage.getItem("deviceToken"),
                "companyID": localStorage.getItem("corpId"),
                "userId": localStorage.getItem("userName"),
                "os": "ionic4",
                "pushStatus": "false"
              };
              const url = serverUrl.web + "/alert/pushnotification";
              this.ajaxService.ajaxPostWithBody(url, pushDetails)
                .subscribe(res => {
                  console.log('push presisted');
                  // this.restartApp();
                })
            }
            if (this.websocketService.isAlive('livetrack')) {
              this.websocketService.disConnectSocket('livetrack');
            }
            this.menuController.enable(false);
            localStorage.clear();
            sessionStorage.setItem('login', 'false')
            localStorage.setItem('login', 'false')
            // this.router.navigateByUrl('login');
            this.authenticationService.logout();
            localStorage.clear();
            // document.location.href = 'index.html';
            // if(app.appName == 'ParentApp'){
            //   document.location.href = 'index.html';
            // }


          }
        }]
      });

      await alert.present();
    } else {
      this.selectedMenu = router;
      if (router == 'Map view') {
        localStorage.setItem('statusChanger', "All")
      }
      this.router.navigateByUrl(this.appUrl[router])
    }
  }

  menuSelection() {

    if (this.appSideMenu == "listMenu") {
      console.log('i am iconMenu')
      this.appSideMenu = "iconMenu";
    }
    else {
      console.log('i am listMenu')
      this.appSideMenu = "listMenu";

    }
  }

  setMenuOver(status) {
    console.log("Mouse Hover", status)
    this.menuOver = status;
  }
}
